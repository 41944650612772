const menuReset = () => {

  const menuBtn = document.getElementById('menuBtn');
  const menu = document.getElementById('menu');

  const input = menuBtn.querySelector('input');

  input.checked = false;
  menu.classList.remove('show');
  
};


/* export default */
const Header = {
  init:()=>{

    const headerElem = document.getElementById('corpHeader');

    if (headerElem != null) {
      
      /*init*/
      menuReset();

      /* event */
      const menuBtn = document.getElementById('menuBtn');
      const menu = document.getElementById('menu');

      menuBtn.addEventListener('click',(e)=>{

        menu.classList.toggle('show');

      });

    }

  }
}

export default Header;