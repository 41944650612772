import CorpHeader from "stylesheets/js/common/corp/_header.js";
import AnchorLink from "stylesheets/js/common/_anchor_link.js";

document.addEventListener("DOMContentLoaded", () => {
  CorpHeader.init();
  AnchorLink();

  // partial copy from /stylesheets/js/common/corp/_include.js
  const backToTop = document.getElementById("backToTop");

  backToTop.addEventListener("click", () => {
    let top = 0;
    window.scrollTo({
      top,
      behavior: "smooth"
    });
  });
});
