import { params } from '../_config';

const AnchorLink = ()=>{

  const btns = document.getElementsByClassName('anchor-link');

  for (let i = 0; i < btns.length; i++) {
    btns[i].addEventListener('click', (e) => {

      e.preventDefault();

      const targetID = e.currentTarget.getAttribute('href').slice(1);;
      //const target = document.getElementById(targetID);
      /*
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
      */
      
      let headerHeight;
      if (params.getPcSp()=='pc'){
        headerHeight = 108;
      } else if(params.getPcSp()=='sp'){
        headerHeight = 113;
      }
      const target = document.getElementById(targetID);
      const rectTop = target.getBoundingClientRect().top;
      const offsetTop = window.pageYOffset;
      const top = rectTop + offsetTop - headerHeight;
      window.scrollTo({
        top,
        behavior: "smooth"
      });
    });
  }
}

export default AnchorLink;